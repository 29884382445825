<script lang="ts" src="./ProductInquiry.ts"></script>


<template>

  <div
    :class="[
      'product-inquiry',
      {
        'in-modal': isInModal,
        'not-allowed': state !== States.SUBSCRIBED,
      }
    ]"
  >

    <div v-if="!isInModal" class="inquiry__header">
      <h6>{{ $trans.t('pages.productIndex.inquiry.title') }}</h6>
    </div>

    <div class="inquiry__body">
      <div v-if="isInModal">
        <p
          class="mt-2"
          @click="handleInquiryButtonClick"
        />
      </div>

      <div v-if="isInModal && productDto.pricingLines" class="py-3 border-y">
        <m-pricing-lines-table :pricingLines="productDto.pricingLines"/>
      </div>

      <div class="m-input-group">
        <label class="label">{{ $trans.t('pages.productIndex.inquiry.targetQuantity') }}<span class="required">*</span></label>

        <m-form-field-number
          v-model="productFormData.model.targetQuantity"
          :disabled="state === States.GUEST || state === States.SELLER"
          :errors="productFormData.errors.targetQuantity"
          :min="0"
          :placeholder="productDto.moq.toString() ?? '0'"
          :step="1"
          @keyup.enter="startOrder"
        >
          <template #prepend>
            <div class="prepend">
              <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="boxes"/>
            </div>
          </template>
        </m-form-field-number>

        <p class="support-text">
          <span :class="((productFormData.model.targetQuantity) && (productDto.moq < productFormData.model.targetQuantity)) ? 'support-text' : ((state === States.GUEST) || (state === States.SELLER) ? '' : 'text-red-400')">
            {{ $trans.t('pages.productIndex.inquiry.moq', {quantity: productDto.moq}) }}
          </span>
        </p>
      </div>

      <div class="m-input-group">
        <label class="label">{{ $trans.t('pages.productIndex.inquiry.targetPrice') }}<span v-if="productDto.pricingLines?.length === 0" class="required">*</span></label>

        <m-form-field-number
          v-if="!productDto.pricingLines || productDto.pricingLines?.length === 0"
          v-model="productFormData.model.targetPrice"
          :disabled="state === States.GUEST || state === States.SELLER"
          :errors="productFormData.errors.targetPrice"
          :min="0"
          :placeholder="state !== States.SUBSCRIBED ? '' : '0'"
          :step=".01"
          @keyup.enter="startOrder"
        >
          <template #prepend>
            <div class="prepend">
              <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="euro-sign"/>
            </div>
          </template>
        </m-form-field-number>

        <strong v-else>{{ $trans.n(calculatedPricePerUnit, 'eur') }}</strong>
      </div>

      <hr>

      <div class="m-input-group">
        <label class="label">{{ $trans.t('pages.productIndex.inquiry.total') }}:</label>
        <strong>{{ $trans.n(totalPrice ?? 0, 'eur') }}</strong>

        <p class="support-text">{{ $trans.t('pages.productIndex.inquiry.shippingCosts') }}</p>
      </div>
    </div>

    <div class="inquiry__footer">
      <p
        v-if="state !== States.SUBSCRIBED"
        @click="handleInquiryButtonClick"
        v-html="unsubscribedDisclaimer"
      />

      <template v-if="state === States.SUBSCRIBED">
        <m-button
          :appearance="$const.StyleAppearance.SECONDARY"
          :disabled="productFormData.model.targetQuantity < productDto.moq"
          :size="$const.StyleSize.MEDIUM"
          class="w-full"
          @click="startOrder"
        >
          {{ $trans.t('pages.productIndex.inquiry.submit') }}
        </m-button>
      </template>

      <template v-else-if="state === States.UNSUBSCRIBED">
        <m-button
          :appearance="$const.StyleAppearance.SECONDARY"
          :size="$const.StyleSize.MEDIUM"
          :to="{ name: 'buyerSubscriptionList' }"
          class="w-full"
        >
          {{ $trans.t('pages.productIndex.inquiry.startSubscriptionBtn') }}
        </m-button>
      </template>

      <template v-else-if="state === States.GUEST">
        <m-button
          :appearance="$const.StyleAppearance.SECONDARY"
          :size="$const.StyleSize.MEDIUM"
          class="w-full"
          @click="register"
        >
          {{ $trans.t('app.loginModal.registerBtn') }}
        </m-button>
      </template>
    </div>
  </div>

</template>

<style lang="scss" scoped>

.product-inquiry:not(.in-modal) {
  @apply border rounded-xl bg-neutral-0;

  &:not(.not-allowed) {
    @apply shadow-lg;
  }

  .inquiry__header {
    @apply p-4 leading-tight border-b;
  }

  .inquiry__body {
    @apply flex flex-col py-4 space-y-4;

    .m-input-group {
      @apply px-4 leading-tight;

      > .label {
        @apply leading-tightest;
      }
    }
  }

  .inquiry__footer {
    @apply flex flex-col p-4 space-y-2 border-t;
  }

  .required {
    @apply text-red-400;
  }

  &.not-allowed .inquiry__body {
    @apply text-neutral-60 bg-neutral-10 bg-opacity-40;

    .m-input-group > .label,
    .m-input-group > .label .required,
    .m-input-group .support-text {
      @apply text-neutral-60;
    }
  }
}

.product-inquiry.in-modal {

  .inquiry__body {
    @apply -mt-4 sm:-mt-8 space-y-4;
  }
}

</style>
