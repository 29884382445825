<script lang="ts" src="./CatalogueInquiry.ts"></script>


<template>

  <div
    :class="[
      'service-inquiry',
      {
        'in-modal': isInModal,
        'not-allowed': !canSubmitForm,
      }
    ]"
  >
    <div v-if="!isInModal" class="inquiry__header">
      <h6>{{ $trans.t('pages.productIndex.inquiry.title') }}</h6>
    </div>

    <m-loading-spinner :loading="flags.loading" class="mx-auto"/>

    <form v-if="!flags.loading && item" class="m-form" @submit.prevent="submit()">
      <div class="inquiry__body">
        <div v-if="isInModal">
          <p
            class="mt-2"
            @click="handleInquiryButtonClick"
          ></p>
        </div>

        <m-form-field-text-area-wrapper
          id="message"
          v-model="formData.model.message"
          :disabled="!canSubmitForm"
          :errors="formData.errors.message"
          :label="$trans.t('app.form.message.label')"
          :maxCharCount="2500"
          :placeholder="$trans.t('app.form.serviceInquiryMessage.placeholder')"
          :required="true"
        />

      </div>

      <div v-if="$store.user.isBuyer" :class="['inquiry__footer', isInModal ? '' : '!mt-0']">
        <p
          v-if="!canSubmitForm"
          @click="handleInquiryButtonClick"
          v-html="unsubscribedDisclaimer"
        ></p>

        <div class="m-form__action-buttons vertical">
          <m-submit-button v-if="canSubmitForm" :label="'app.buttonLabels.sendMessage'"/>
          <m-submit-button v-else :label="'pages.productIndex.inquiry.startSubscriptionBtn'" @click="toSubscriptionList"/>
        </div>
      </div>
    </form>
  </div>

</template>

<style lang="scss" scoped>

.service-inquiry:not(.in-modal) {
  @apply border rounded-xl bg-neutral-0;

  &:not(.not-allowed) {
    @apply shadow-lg;
  }

  .inquiry__header {
    @apply p-4 leading-tight border-b;
  }

  .inquiry__body {
    @apply flex flex-col py-4 space-y-4;

    .m-input-group {
      @apply px-4 leading-tight;
    }
  }

  .inquiry__footer {
    @apply flex flex-col p-4 space-y-2 border-t;
  }

  .required {
    @apply text-red-400;
  }

  &.not-allowed .inquiry__body {
    @apply text-neutral-60 bg-neutral-10 bg-opacity-40;

    .m-input-group > .label,
    .m-input-group > .label .required,
    .m-input-group .support-text {
      @apply text-neutral-60;
    }
  }
}

.service-inquiry.in-modal {

  .inquiry__body {
    @apply -mt-4 sm:-mt-8 space-y-4;
  }
}

</style>
