import Bluebird from 'bluebird';
import {Component, Prop, Vue} from 'vue-facing-decorator';
import {Main} from '@/app/Main';
import {Routes} from '@/app/Routes';
import {Roles} from '@/app/constants/Roles';
import {PublicCatalogueServiceDTO} from '@/app/dto/PublicCatalogueServiceDTO';
import {Lang} from '@/app/lang/Lang';
import {Store} from '@/app/stores/Store';
import {FormData} from '@/app/utils/FormData';
import {FormRules, FormRulesById} from '@/app/utils/FormRules';
import {RequestMethods} from '@/libs/core/constants/RequestMethods';
import {ThreadDTO} from '@/app/dto/ThreadDTO';
import {PublicCatalogueFabricDTO} from "@/app/dto/PublicCatalogueFabricDTO";

type CatalogueFormModel = {
    message?: string,
};

enum States {
    UNSUBSCRIBED = 'unsubscribed',
    SUBSCRIBED = 'subscribed',
}

@Component({})
export default class CatalogueInquiry extends Vue {

    public readonly flags = {
        loading: false,
    };

    public readonly States = States;

    @Prop({type: Boolean, default: false})
    public readonly isInModal: boolean;

    @Prop({type: Boolean, default: false})
    public readonly needsSubscription: boolean;

    @Prop({type: Object, required: true})
    public readonly item: PublicCatalogueServiceDTO | PublicCatalogueFabricDTO;

    public formData: FormData<CatalogueFormModel> = null;

    public get subscriptionState(): States {
        if (Store.user.isBuyer && Store.user.hasRoles(Roles.BUYER_TIER_1)) {
            return States.SUBSCRIBED;
        } else {
            return States.UNSUBSCRIBED;
        }
    }

    public get unsubscribedDisclaimer(): string {
        if (!this.canSubmitForm) {
            return Main.trans.t('pages.productIndex.inquiry.noSubscription');
        }
    }

    public get canSubmitForm(): boolean {
        if (!this.needsSubscription) {
            return true;
        }

        return this.subscriptionState === States.SUBSCRIBED
    }

    public handleInquiryButtonClick(): void {
        if (this.canSubmitForm) {
            return Main.app.openCatalogueInquiryModal(this.item);
        }

        Routes.resolveLink({name: 'buyerSubscriptionList'});
    }

    public created(): void {
        const productFormRules: FormRulesById = {};
        productFormRules.message = [FormRules.required(), FormRules.maxLength(2500)];

        this.formData = new FormData({}, productFormRules);
    }

    public register(): void {
        Main.app.openRegisterModal();
    }

    public toSubscriptionList(): void {
        Routes.resolveLink({name: 'buyerSubscriptionList'});
    }

    public submit(): void {
        if (!this.canSubmitForm) return;

        const formIsValid: boolean = this.formData.validate();

        if (!formIsValid) {
            return;
        }

        let url = this.item.type + '/inquiry';

        if (this.item.isStrategy) {
            url = 'public/strategy/inquiry';
        }

        const payload: Record<string, any> = {};
        payload.uuid = this.item.uuid;
        payload.message = this.formData.model.message;

        let promise: Bluebird<any> = Main.callApi(url, RequestMethods.POST, payload, ThreadDTO);
        promise = promise.then(this.handleSubmitResult.bind(this));
        promise = promise.finally(() => {
            this.flags.loading = false;
        });
    }

    private handleSubmitResult(result: ThreadDTO): void {
        Main.app.toast.success(Main.trans.t(Lang.t.app.toast.success.offerPlaced.message), Main.trans.t(Lang.t.app.toast.success.offerPlaced.title));

        Routes.resolveLink({name: 'chat', query: {threadUuid: result.uuid}});

        this.$emit('success');
    }

}
