<script lang="ts" src="./ManufyValueIcons.ts"></script>

<template>

  <ul class="flex flex-row space-x-2">
    <li v-for="(value, index) in values.slice(0, maxIcons)">
      <div class="flex">
        <m-tooltip :label="$trans.t('meta.manufyValues.' + value)" class="tooltip">
          <m-button
            :appearance="appearance"
            :icon="{ name: value, solid: true, type: $const.IconTypes.MANUFY }"
            :isLight="isLight"
            :shape="shape"
            :size="size"
            :variant="variant"
            @click="openList()"
          />
        </m-tooltip>
      </div>
    </li>

    <li v-if="values.length > maxIcons">
      <div class="flex">
        <m-button
          :appearance="$const.StyleAppearance.SECONDARY"
          :isLight="isLight"
          :shape="shape"
          :size="size"
          :variant="variant"
          class="body-font"
          @click="openList()"
        >
          + {{ values.length - maxIcons }}
        </m-button>
      </div>
    </li>
  </ul>

</template>


<style lang="scss" scoped>
</style>
