<script lang="ts" src="./CompanyShortCard.ts"></script>

<template>
  <div class="company-card overflow-hidden">
    <div v-if="availabilityStatusLabel" :class="['p-2 leading-tight text-center text-tiny ', availabilityStatusColor]">
      {{ availabilityStatusLabel }}
    </div>

    <div class="company-card__header">

      <div class="company-card__avatar">
        <m-avatar
          :appearance="$const.StyleAppearance.PRIMARY"
          :partner="company.isPartner"
          :size="$const.StyleSize.MEDIUM"
          :url="company.avatar?.getThumbUrl()"
          :verified="company.isVerified"
        />
      </div>

      <div class="company-card__meta">
        <m-link
          v-if="!isManufy"
          :new-window="true"
          :to="companyPublicPage"
        >
          <h3 class="company-card__name">
            {{ company.name }}
          </h3>
        </m-link>

        <h3 v-else class="company-card__name">
          {{ company.name }}
        </h3>

        <p v-if="company.primaryLocation" class="company-card__location">
          <m-icon :size="$const.StyleSize.SMALL" :solid="true" :type="$const.IconTypes.HERO" class="icon" name="map-pin"/>
          {{ company.primaryLocation?.formattedString }}
        </p>
      </div>
    </div>

    <div v-if="!isManufy" class="company-card__body">
      <div class="company-card__row">
        <div class="row__item">
          <small class="m-caption">{{ $trans.t('pages.productIndex.lastSeen') }}</small>
          <p class="item__value">
            <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="icon" name="calendar-day"/>
            <!-- TODO: see if cast correctly to fix? -->
            <span class="text">{{ company.lastSeenAtHumanRead }}</span>
          </p>
        </div>

        <div v-if="company.subscriptionTier" class="row__item">
          <small class="m-caption">{{ $trans.t('app.labels.subscriptionTier') }}</small>

          <p v-if="company.subscriptionTier" class="item__value">
            <m-icon :class="['icon', isSubscribed ? 'text-green-400' : 'text-red-400']" :name="subscriptionIcon.name" :solid="subscriptionIcon.solid" :type="subscriptionIcon.type"/>
            <span class="text">{{ $trans.t('pages.companyShortCard.subscription.' + company.subscriptionTier) }}</span>
          </p>
        </div>

        <div v-if="company.companyTurnover" class="row__item row__item--w-full">
          <small class="m-caption">{{ $trans.t('pages.productIndex.yearlyRevenueRange') }}</small>
          <p class="item__value">
            <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="icon" name="money-bill"/>
            <span class="text">{{ $trans.t('meta.annualTurnovers.' + company.companyTurnover) }}</span>
          </p>
        </div>
      </div>
    </div>

    <div v-if="!isManufy" class="company-card__footer">
      <m-button
        :appearance="$const.StyleAppearance.NEUTRAL_NEW"
        :new-window="true"
        :size="$const.StyleSize.SMALL"
        :to="companyPublicPage"
        class="flex-1"
      >
        {{ $trans.t('app.labels.viewProfile') }}
      </m-button>

      <m-button
        v-if="($store.user.isBuyer || $store.user.user == null) && company.isVerified"
        :appearance="$const.StyleAppearance.NEUTRAL_NEW"
        :icon-before="{ name: 'paper-plane', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
        :size="$const.StyleSize.SMALL"
        class="flex-1"
        @click="sendProjectToSeller()"
      >
        {{ $trans.t('app.labels.sendInquiry') }}
      </m-button>

      <m-button
        v-if="$store.user.isAdmin"
        :appearance="$const.StyleAppearance.DARK"
        :icon-before="{ name: 'user-circle', solid: true, type: $const.IconTypes.HERO, }"
        :new-window="true"
        :size="$const.StyleSize.SMALL"
        :to="{ name: company.companyType === CompanyTypes.SELLER ? 'adminSellerRead' : 'adminBuyerRead', params: { uuid: company.uuid } } "
        :variant="$const.StyleVariants.OUTLINE"
      >
        {{ $trans.t('app.labels.viewInAdminPanel') }}
      </m-button>
    </div>
  </div>

</template>

<style lang="scss" scoped>


.company-card {
  @apply flex flex-col relative border rounded-xl bg-neutral-0;

  .company-card__header {
    @apply flex flex-col p-4 space-y-3 leading-tight xs:flex-row xs:items-center xs:space-x-3 xs:space-y-0;

    .company-card__meta {
      @apply flex flex-col items-center space-y-1 xs:items-start;
    }
  }

  .company-card__avatar {
    @apply flex-none self-center;
  }

  .company-card__name {
    @apply text-base text-center line-clamp-1 xs:text-left;
  }

  .company-card__location {
    @apply flex flex-row justify-center text-tiny text-left text-neutral-80;

    .icon {
      @apply flex-none mt-px mr-0.5 mb-px text-neutral-400;
    }
  }

  .company-card__body {
    @apply flex-1 p-4 space-y-4 border-y;
  }

  .company-card__row {
    @apply flex flex-col flex-wrap -mt-4 xs:flex-row xs:mt-0 xs:-ml-4 sm:-mt-4;

    .row__item {
      @apply min-w-24 flex-1 text-left mt-4 xs:mt-0 xs:ml-4 sm:mt-4;

      &--w-full {
        @apply min-w-full max-w-full;
      }

      .item__value {
        @apply flex items-center leading-tight;

        .icon {
          @apply mb-px text-sm;
        }

        .text {
          @apply ml-1.5 text-tiny;
        }

        &.na .icon {
          @apply text-neutral-60;
        }
      }
    }
  }

  .company-card__footer {
    @apply flex flex-col p-4 space-y-3 xs:flex-row xs:space-x-4 xs:space-y-0 sm:flex-col sm:space-x-0 sm:space-y-3;
  }
}

</style>
