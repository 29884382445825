export class Settings {

    // Environment values
    public static debugMode: boolean = (import.meta.env.VITE_DEBUG_MODE.toLowerCase() === 'true');

    public static appTitle: string = import.meta.env.VITE_TITLE;
    public static appVersion: string = import.meta.env.VITE_VERSION;
    public static appBaseUrl: string = import.meta.env.BASE_URL;
    public static appPath: string = import.meta.env.VITE_PATH;

    public static clientStoragePrefix: string = import.meta.env.VITE_STORAGE_PREFIX;
    public static recaptchaSiteKey: string = import.meta.env.VITE_RECAPTCHA_SITE_KEY;
    public static intercomKey: string = import.meta.env.VITE_INTERCOM_KEY;

    public static stripeUrl: string = import.meta.env.VITE_URL;
    public static stripeKey: string = import.meta.env.VITE_STRIPE_KEY;
    public static stripeBuyerPricingTableKey: string = import.meta.env.VITE_STRIPE_BUYER_PRICING_TABLE_KEY;
    public static stripeNewBuyerPricingTableKey: string = import.meta.env.VITE_STRIPE_NEW_BUYER_PRICING_TABLE_KEY;
    public static stripeSellerPricingTableKey: string = import.meta.env.VITE_STRIPE_SELLER_PRICING_TABLE_KEY;
    public static stripeNewSellerPricingTableKey: string = import.meta.env.VITE_STRIPE_NEW_SELLER_PRICING_TABLE_KEY;

    public static algoliaAppId: string = import.meta.env.VITE_ALGOLIA_APP_ID;
    public static algoliaSearchKey: string = import.meta.env.VITE_ALGOLIA_SEARCH_KEY;

    public static googleMapsKey: string = import.meta.env.VITE_GOOGLE_MAPS_KEY;

    public static companyPingInterval: number = 15000;

    // Hardcoded names:
    public static nameOpp: string = 'Online Payment Platform';   // To make sure it's not translated

    // public static passwordRegEx:RegExp = /^(?=.*[A-Za-z])(?=.*[\d])[a-zA-Z\d\w\W]{8,}$/  // Min eight chars, at least 1 letter and 1 number See https://stackoverflow.com/a/21456918/1572330
    public static passwordRegEx: RegExp = /^(?=.*[A-Za-z])(?=.*[\d\W_])[a-zA-Z\d\w\W]{8,}$/  // Min eight chars, at least 1 letter and 1 number or special char See https://stackoverflow.com/a/21456918/1572330
    public static phoneRegEx: RegExp = /^[0-9]{5,15}$/  // Check if only digits are used and number is between 5 and 15 digits long

    // URL's
    public static urlMainSite: string = 'https://www.manufy.com';
    public static urlTerms: string = 'https://manufy.com/terms-and-conditions';
    public static urlPrivacy: string = 'https://www.manufy.com/privacy-policy/';
    public static urlBuyerProtection: string = 'https://manufy.com/for-brands';
    public static urlCO2Compensation: string = 'https://www.manufy.com/co2-compensation/';
    public static urlOppTerms: string = 'https://onlinepaymentplatform.com/en/terms-policies';
    public static urlOppFaq: string = 'https://help.manufy.com/en/articles/6618757-how-to-get-kyc-verified';
    public static urlRfqFaq: string = 'https://help.manufy.com/en/articles/5529353-how-do-i-place-a-request-for-quotation-rfq';
    public static urlSupportMail: string = 'support@manufy.com';
    public static urlTypeForm: string = 'https://f26cesflpz9.typeform.com/to/';
    public static urlImageGuidelines: string = 'https://help.manufy.com/en/articles/6803459-image-guideline';
    public static urlPaymentInstructions: string = 'https://help.manufy.com/en/articles/7007878-payment-instruction-video';
    public static urlPaymentPendingInstructions: string = 'https://help.manufy.com/en/articles/7003761-what-to-do-when-payment-is-pending';
    public static urlManufyValuesExplanationSellers: string = 'https://manufy.com/manufy-values-for-manufacturers';
    public static urlManufyValuesExplanationBuyers: string = 'https://manufy.com/manufy-values-for-brands';
    public static urlTechPackAssistance: string = 'https://manufy.com/extra-services/design-consulting';

    public static urlBookCall: string = 'https://calendly.com/d/cmgj-qqg-dsw';
    public static urlOnboarding: string = 'https://calendly.com/d/cp7f-sjc-6gh/1-1-verification-onboarding-15-min';

    /**
     * Get the parsed api url.
     */
    public static get apiUrl(): string {
        return Settings.parseUrl(import.meta.env.VITE_API_URL);
    }

    /**
     * Get a prefix based on the env the site is in.
     */
    public static get envPrefix(): string {
        const host: string = window.location.host;

        let channelPrefix: string = '';
        if (host.includes('app.manufy.com')) {
            channelPrefix = Settings.getUrlPrefix();
            if (channelPrefix.length == 0) {
                channelPrefix = 'production';
            }
        } else {
            channelPrefix = import.meta.env.VITE_ENV;   // Probably running on something like `localhost`
        }

        return channelPrefix;
    }

    /**
     * Boolean if code runs on production.
     */
    public static get isProduction(): boolean {
        return Settings.envPrefix == 'production';
    }

    /**
     * Boolean if code runs on staging.
     */
    public static get isStaging(): boolean {
        return Settings.envPrefix == 'staging';
    }

    /**
     * Boolean if code runs on sandbox.
     */
    public static get isSandbox(): boolean {
        return Settings.envPrefix == 'sandbox';
    }

    /**
     * Boolean if code runs on local.
     */
    public static get isLocal(): boolean {
        return Settings.envPrefix == 'local';
    }

    /**
     * Get the prefix of the url (local, sandbox, staging, ) based on the current url.
     * Will be empty in case of production!
     */
    public static getUrlPrefix(): string {
        const host: string = window.location.host;
        const parts: string[] = host.split('.');
        parts.splice(-3, 3);    // remove the app.manufy.com part
        return parts.join('.');
    }

    /**
     * Parse the given url based on the current stage.
     */
    private static parseUrl(url: string): string {
        const stage = Settings.getUrlPrefix();
        return url.replace('%STAGE%', (stage.length > 0) ? stage + '.' : stage);
    }
}
