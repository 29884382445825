import {BaseDTO} from "@/app/dto/BaseDTO";
import {LocationDTO} from "@/app/dto/LocationDTO";
import {CastableProperties} from "@/app/utils/Castable";
import {FileDTO} from "@/app/dto/FileDTO";
import {CompanyTypes} from "@/app/constants/CompanyTypes";
import {CompanyTotalEmployees} from "@/app/constants/CompanyTotalEmployees";
import {CompanyTurnover} from "@/app/constants/CompanyTurnover";
import {CompanyAvailabilityStatus} from '@/app/constants/CompanyAvailabilityStatus';
import {CompanySubscriptionTierTypes} from "@/app/constants/CompanySubscriptionTierTypes";

export class CompanyShortDTO extends BaseDTO {

    public slug: string = null;

    public name: string = null;
    public companyType: CompanyTypes = null;
    public subscriptionTier: string = null;
    public availabilityStatus: CompanyAvailabilityStatus = null;
    public availabilityStatusUpdatedAt: Date = null;

    public createdAt: Date = null;
    public lastSeenAt: Date = null;

    public isVerified: boolean = null;
    public isPartner: boolean = null;
    public isManufacturer: boolean = null;
    public isManufy: boolean = null;

    public registrationCountry: string = null;

    public companyTurnover: CompanyTurnover = null;
    public totalEmployees: CompanyTotalEmployees = null;

    public avatar: FileDTO = null;

    public primaryLocation: LocationDTO = null;

    protected static get castParams(): CastableProperties {
        return {
            ...super.castParams,
            createdAt: Date,
            lastSeenAt: Date,
            availabilityStatusUpdatedAt: Date,
            avatar: FileDTO,
            primaryLocation: LocationDTO,
        };
    }

    public get lastSeenAtHumanRead(): string {
        return this.lastSeenAt.timeSinceHumanRead();
    }

    public get isSubscribed(): boolean {
        if (this.companyType === CompanyTypes.MANUFY) {
            return true;
        }

        return this.subscriptionTier &&
            this.subscriptionTier !== CompanySubscriptionTierTypes.BUYER_TIER_0 &&
            this.subscriptionTier !== CompanySubscriptionTierTypes.SELLER_TIER_0;
    }

    public get adminReadPageRoute(): null | { name: string, params: { uuid: string } } {
        if (this.companyType === CompanyTypes.BUYER) {
            return {name: 'adminBuyerRead', params: {uuid: this.uuid}};
        }
        if (this.companyType === CompanyTypes.SELLER) {
            return {name: 'adminSellerRead', params: {uuid: this.uuid}};
        }

        return null;
    }
}
